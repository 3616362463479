import React, { useState, useRef } from 'react';
import { Box, Button, Container, Divider, Typography, Paper, TextField, FormControl, Select, MenuItem, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const BASE_URL = process.env.REACT_APP_BASE_URL;
const access_token = localStorage.getItem('auth-token');
const urlParams = 'library/resource/create';

const AddCourse = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const fileInputRef = useRef();
  const fileInput2Ref = useRef();
  const fileInput3Ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState();
  const [resourceTitle, setResourceTitle] = useState();
  const [resourceType, setResourceType] = useState();
  const [resourceUrl, setResourceUrl] = useState();
  const [article, setArticle] = useState();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleButton2Click = () => {
    fileInput2Ref.current.click();
  };

  const handleButton3Click = () => {
    fileInput3Ref.current.click();
  };

  function handleReset() {
    setThumbnailUrl('');
    setResourceTitle('');
    setResourceType('');
    setArticle('');
    setResourceUrl('');
  }

  const handleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('thumbnailUrl', thumbnailUrl);
    formData.append('resourceType', resourceType);
    formData.append('resourceUrls', resourceUrl);
    formData.append('resourceTitle', resourceTitle);
    formData.append('article', article);

    // console.log('ARTICLE', formData);
    try {
      const response = await axios.post(
        `${BASE_URL}${urlParams}`,
        formData,
        { headers: { Authorization: 'Bearer ' + access_token } }
      );
      // if (response?.data?.status) {
      //   toast.success('Resource Added Successfully');
      // } else {
      //   toast.error('Error Adding Resource');
      // }
      if (response?.data?.status) {
      setData(response.data.data);
      setIsLoading(false);
      toast.success('Resource Added Successfully');
      handleReset();}
    } catch (error) {
      toast.error('An error occurred while adding the resource');
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Container className="flex flex-col items-start justify-start">
      <ToastContainer />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
        <Box>
          <Typography variant="h4" color="blue" fontWeight="bold">
            Learning Management System
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Administer and oversee trainings and courses within the platform.
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 1 }} />

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <ArrowBackIcon sx={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
            <Typography variant="h5" align="left" ml={2} sx={{ flexGrow: 1 }}>
              Create Library Resource
            </Typography>
          </Box>
          <Paper sx={{ p: 3, mb: 4, border: '1px solid #e0e0e0', borderRadius: '10px', borderColor: 'blue', width: '100%' }}>
            <Typography variant="h6" sx={{ mb: 1, fontWeight: 'bold' }}>
              Resource Thumbnail
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <Box sx={{ width: '30%', mr: 2 }}>
                {!thumbnailUrl && (
                  <Paper className="flex flex-col items-center justify-center" variant="outlined" sx={{ p: 2, textAlign: 'center', height: 150 }}>
                    <CloudUploadIcon className="flex" sx={{ fontSize: 50, color: 'gray', margin: '0 auto' }} />
                  </Paper>
                )}
                {thumbnailUrl && <img src={URL.createObjectURL(thumbnailUrl)} alt="thumbnail" style={{ width: '100%', height: '100%' }} />}
              </Box>
              <Box sx={{ width: '70%' }}>
                <Typography variant="body2" color="textSecondary">
                  Upload your course Thumbnail here. Important guidelines: 1200x800 pixels or 12:8 Ratio. Supported format: .jpg, .jpeg, or .png
                </Typography>
                <Button htmlFor="upload" onClick={handleButtonClick} variant="contained" sx={{ mt: 2 }} style={{ backgroundColor: 'blue' }}>
                  Upload image
                </Button>
                <input ref={fileInputRef} className="hidden" onChange={(e) => setThumbnailUrl(e.target.files[0])} type="file" id="upload" />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: 5 }}>
              <TextField value={resourceTitle} onChange={(e) => setResourceTitle(e.target.value)} id="outlined-basic" label="Resource Title" variant="outlined" fullWidth sx={{ mb: 3 }} />
              <FormControl fullWidth sx={{ mb: 3 }}>
                <Select
                  label="Resource Type"
                  id="Resource type"
                  defaultValue="Resource Type"
                  onChange={(e) => setResourceType(e.target.value)}
                  value={resourceType}
                >
                  <MenuItem value="Video">Video</MenuItem>
                  <MenuItem value="Article">Article</MenuItem>
                  <MenuItem value="PDF">PDF</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {resourceType === 'Article' && (
              <Box className="flex h-auto w-full flex-col items-start justify-start py-2 pb-4">
                <label className="p-1 text-sm font-semibold text-gray-700" htmlFor="article-here">
                  Article here
                </label>
                <textarea
                  onChange={(e) => setArticle(e.target.value)}
                  id="article-here"
                  className="h-52 w-full border border-solid border-gray-100 bg-slate-100 p-1"
                  placeholder="Type here"
                />
                <Button onClick={handleButton3Click} className="flex h-11 w-full flex-row items-center justify-center">
                  Article Upload
                </Button>
                <input ref={fileInput3Ref} className="hidden" onChange={(e) => setResourceUrl(e.target.files[0])} type="file" id="upload" />
              </Box>
            )}
            {resourceUrl && resourceType !== 'Article' && (
              <object data={URL.createObjectURL(resourceUrl)} type={`${resourceType === 'PDF' ? 'application/pdf' : 'video/mp4'}`} width="100%" height="500px" aria-label="PDF" />
            )}
            {['Video', 'PDF'].includes(resourceType) && (
              <Box className="flex h-auto w-full flex-col items-start justify-start py-2 pb-4">
                <Button onClick={handleButton2Click} className="flex h-11 w-full flex-row items-center justify-center">
                  Video/PDF Upload
                </Button>
                <input
                  ref={fileInput2Ref}
                  className="hidden"
                  onChange={(e) => {
                    console.log(e.target.files);
                    setResourceUrl(e.target.files[0]);
                  }}
                  type="file"
                  id="upload"
                />
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button className="h-11" type="button" onClick={() => handleReset()} variant="outlined" sx={{ width: '48%' }}>
                Cancel
              </Button>
              <Button className="h-11" type="submit" onClick={() => handleSubmit()} variant="contained" sx={{ width: '48%' }} style={{ backgroundColor: 'blue' }}>
                {isLoading ? <CircularProgress className="h-11" /> : 'Save'}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Container>
  );
};

export default AddCourse;
